import React, { Fragment, useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import WhiteboardIcon from '@atlaskit/icon/core/whiteboard';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { Ati } from '@atlassian/ari';

import {
	CONTENT_CREATION_PAYWALL_EXPERIENCE,
	ExperienceFailure,
	ExperienceStart,
} from '@confluence/experience-tracker';
import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
// import { useSessionData, type AccessStatus } from '@confluence/session-data';
import { useIsAllowedToCreateWhiteboard } from '@confluence/content-types-utils';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import {
	CreateWhiteboardExperimentPopoverItemSpotlight,
	useWhiteboardNudgeExperiments,
} from '@confluence/content-types-experiments';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';

import type { UseMenuItemProps, UseMenuItemReturnProps } from '.';

import type { WhiteboardCreationLinkOptions } from '../../CreateContentRouteComponent';
import { buildCreationLink } from '../../CreateContentRouteComponent';
import { useHybridLinkClick } from '../../CreateContentPopover/CreateContentPopoverItems/hybridLinkHelper';
import {
	collectStartCreateWhiteboardExperience,
	useWhiteboardCreationPaywall,
} from '../../CreateContentPopover/CreateContentPopoverItems/useWhiteboardCreationPaywall';
import type { WhiteboardCreationPaywallDialogProps } from '../../CreateContentPopover/CreateContentPopoverItems/WhiteboardCreationPaywallDialog';
import { WhiteboardCreationPaywallDialog } from '../../CreateContentPopover/CreateContentPopoverItems/WhiteboardCreationPaywallDialog';
import { PaywallStatus } from '../../createContentInputTypes';

const GLOBAL_CREATE_EXPERIMENT = 'cc-whiteboard-nudge-global-create-experiment';
const GLOBAL_CREATE_EXPERIMENT_PARAM = 'globalCreateCohort';

export const globalCreateExperimentDataMap: {
	[key: string]: string;
} = {
	experimentName: GLOBAL_CREATE_EXPERIMENT,
	cohort: GLOBAL_CREATE_EXPERIMENT_PARAM,
};

const messages = defineMessages({
	title: {
		id: 'create-blank-fabric-page.universal-create.whiteboard.title',
		defaultMessage: 'Whiteboard',
		description: 'title indicating that this option will create a whiteboard',
	},
	toolTipTextAnonymous: {
		id: 'create-blank-fabric-page.universal-create.whiteboard.tooltip.text-anonymous',
		defaultMessage: 'Log in to create a whiteboard',
		description: 'tooltip message telling user to sign in to create a whiteboard',
	},
	toolTipTextUnlicensed: {
		id: 'create-blank-fabric-page.universal-create.whiteboard.tooltip.text-unlicensed',
		defaultMessage: 'Sign up to Confluence to create a whiteboard',
		description: 'tooltip message telling user to sign up for Confluence to create a whiteboard',
	},
});

const PAYWALL_EXPERIENCE_ATTRIBUTES = {
	contentType: 'whiteboard',
	paywallFlow: 'upfront',
};

export function useWhiteboardItem({
	parentId,
	source,
	spaceKey,
	onClick,
}: UseMenuItemProps): UseMenuItemReturnProps {
	const { formatMessage } = useIntl();
	const isAllowedToCreateWhiteboard = useIsAllowedToCreateWhiteboard();
	// const { accessStatus } = useSessionData();
	const { showModal } = useDialogs();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const {
		deactivationIdentifier,
		error: creationPaywallError,
		paywallStatus,
		showPaywallBeforeCreate,
	} = useWhiteboardCreationPaywall();
	const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
		source: 'create-whiteboard-popover-item',
	});

	const buildWhiteboardCreationLink = (
		creationOverrides: Partial<WhiteboardCreationLinkOptions> = {},
		sourceSuffix = '',
	) => {
		return buildCreationLink({
			contentType: 'whiteboard',
			parentPageId: parentId,
			withFallback: true,
			source: source + sourceSuffix,
			spaceKey,
			deactivationIdentifier,
			paywallStatus,
			...creationOverrides,
		});
	};

	const createHref = buildWhiteboardCreationLink();

	const hybridLinkClick = useHybridLinkClick(createHref);

	const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
		onClick('createWhiteboardPopoverItem');
		if (showPaywallBeforeCreate) {
			showModal<WhiteboardCreationPaywallDialogProps>(WhiteboardCreationPaywallDialog, {
				buildWhiteboardCreationLink,
			});
		} else {
			if (paywallStatus === PaywallStatus.UNSET) {
				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: {
						action: 'clicked',
						actionSubject: 'createUnsetWhiteboardLink',
						source,
						attributes: {
							reason: creationPaywallError ? 'error' : 'loading',
						},
					},
				}).fire();
			}
			hybridLinkClick(e);
		}
	};

	// TODO: add tooltip back post T25
	// const getTooltipContent = (accessStatusArg: AccessStatus | null): string | undefined => {
	// 	if (!isAllowedToCreateWhiteboard) {
	// 		if (accessStatusArg === null) {
	// 			return formatMessage(messages.toolTipTextAnonymous);
	// 		} else {
	// 			return formatMessage(messages.toolTipTextUnlicensed);
	// 		}
	// 	}
	// 	return undefined;
	// };

	// const toolTipContent = getTooltipContent(accessStatus);

	// Whiteboard Nudge Campaign Experiment
	const { getExperiment, skipCoordinationClientForExperiment } = useWhiteboardNudgeExperiments(
		GLOBAL_CREATE_EXPERIMENT,
		GLOBAL_CREATE_EXPERIMENT_PARAM,
	);

	const [isWhiteboardExperimentationSpotlightActive, stopWhiteboardExperimentationSpotlight] =
		useSkippableCoordination(
			'cc-whiteboards-nudge-exp-spotlight-v2',
			shouldBlockCreate || skipCoordinationClientForExperiment,
		);
	const [isSpotlightOpen, setIsSpotlightOpen] = useState(true);
	const isEligibleToSeeSpotlight =
		isWhiteboardExperimentationSpotlightActive &&
		getExperiment(
			globalCreateExperimentDataMap['experimentName'],
			globalCreateExperimentDataMap['cohort'],
			true,
		) === 'variation';

	const handleSpotlightClose = useCallback(() => {
		setIsSpotlightOpen(false);
		void stopWhiteboardExperimentationSpotlight();
	}, [stopWhiteboardExperimentationSpotlight, setIsSpotlightOpen]);

	const WhiteboardSpotlightWrapper = ({ children }) => (
		<CreateWhiteboardExperimentPopoverItemSpotlight
			createHref={createHref}
			spotlightDialogPlacement="right middle"
			handleSpotlightClose={handleSpotlightClose}
			handleClosePopoverMenu={handleClick}
			source={source}
			shouldWatchTarget
		>
			{children()}
		</CreateWhiteboardExperimentPopoverItemSpotlight>
	);

	return {
		ati: Ati.parse('ati:cloud:confluence:whiteboard'),
		children: (
			<Fragment>
				<ExperienceStart
					name={CONTENT_CREATION_PAYWALL_EXPERIENCE}
					collect={collectStartCreateWhiteboardExperience}
					attributes={PAYWALL_EXPERIENCE_ATTRIBUTES}
				/>
				{creationPaywallError && (
					<ExperienceFailure
						name={CONTENT_CREATION_PAYWALL_EXPERIENCE}
						error={creationPaywallError}
					/>
				)}
				{formatMessage(messages.title)}
			</Fragment>
		),
		onClick: enforceStorageLimit(handleClick),
		elemBefore: WhiteboardIcon,
		...(!shouldBlockCreate && isAllowedToCreateWhiteboard && { href: createHref }),
		...(isSpotlightOpen &&
			isEligibleToSeeSpotlight &&
			isAllowedToCreateWhiteboard && {
				Wrapper: WhiteboardSpotlightWrapper,
			}),
		testId: `create-menu-${shouldBlockCreate ? 'button' : 'link'}-item`,
	};
}

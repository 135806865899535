import React, { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import PageLiveDocIcon from '@atlaskit/icon-lab/core/page-live-doc';

import { Ati } from '@atlassian/ari';

import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { CreateButtonSpotlightPulseWrapper } from '@confluence/onboarding-live-doc-changeboarding/entry-points/CreateButtonSpotlightPulseWrapper';
import { CreateLiveDocPopoverItemSpotlight } from '@confluence/onboarding-live-doc-changeboarding/entry-points/CreateLiveDocPopoverItemSpotlight';
import { useLiveDocChangeboarding } from '@confluence/onboarding-live-doc-changeboarding/entry-points/useLiveDocChangeboarding';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { ErrorDisplay } from '@confluence/error-boundary';

import type { UseMenuItemProps, UseMenuItemReturnProps } from '.';

import { buildCreationLink } from '../../CreateContentRouteComponent';
import { useHybridLinkClick } from '../../CreateContentPopover/CreateContentPopoverItems/hybridLinkHelper';
import { ConfluencePageSubType } from '../../queries/__types__/CreateBlankPageMutation';
import { ItemTitleWithLozenge } from '../ItemTitle';
const messages = defineMessages({
	title: {
		id: 'create-blank-fabric-page.universal-create.liveDoc.title',
		defaultMessage: 'Live Doc',
		description: 'title indicating that this option will create a live doc',
	},
	lozenge: {
		id: 'create-blank-fabric-page.universal-create.liveDoc.lozenge',
		defaultMessage: 'Beta',
		description:
			'Text for the beta lozenge. Informs users that the associated feature is in a beta phase.',
	},
});

const CREATE_LIVE_DOC_POPOVER_ITEM = 'create-live-doc-popover-item';

export function useLiveDocItem({
	parentId,
	source,
	spaceKey,
	onClick,
}: UseMenuItemProps): UseMenuItemReturnProps {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
		source: CREATE_LIVE_DOC_POPOVER_ITEM,
	});

	const createHref = buildCreationLink({
		contentType: 'page',
		subType: ConfluencePageSubType.LIVE,
		parentPageId: parentId,
		withFallback: true,
		source,
		spaceKey,
	});

	const hybridLinkClick = useHybridLinkClick(createHref);
	const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
		onClick('createLiveDocPopoverItem');
		hybridLinkClick(e);
	};

	const { push } = useRouteActions();
	const { introSpotlight, error } = useLiveDocChangeboarding();
	const { isVisible: shouldShowIntroSpotlight, complete: completeIntroSpotlight } = introSpotlight;
	const [hasFiredOnStartEvent, setHasFiredOnStartEvent] = useState(false);

	const onSpotlightStart = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'shown',
				actionSubject: 'spotlight',
				actionSubjectId: 'liveDocIntro',
				source,
			},
		}).fire();
	}, [createAnalyticsEvent, source]);

	useEffect(() => {
		if (shouldShowIntroSpotlight && !hasFiredOnStartEvent) {
			onSpotlightStart();
			setHasFiredOnStartEvent(true);
		}
	}, [shouldShowIntroSpotlight, hasFiredOnStartEvent, onSpotlightStart]);

	const LiveDocSpotlightWrapper = ({ children }) => (
		<CreateButtonSpotlightPulseWrapper
			condition={shouldShowIntroSpotlight}
			wrapper={(element) => (
				<CreateLiveDocPopoverItemSpotlight
					handleDismiss={() => {
						completeIntroSpotlight();
					}}
					handleProceed={enforceStorageLimit((e) => {
						completeIntroSpotlight();
						handleClick(e);
						push(createHref);
					})}
					targetName={CREATE_LIVE_DOC_POPOVER_ITEM}
					source={source}
					fireAnalyticsEventOnMessageDisposition={false} // default is true, manually disabling it
				>
					{element}
					{error && <ErrorDisplay error={error} />}
				</CreateLiveDocPopoverItemSpotlight>
			)}
		>
			{children()}
		</CreateButtonSpotlightPulseWrapper>
	);

	return {
		ati: Ati.parse('ati:cloud:confluence:livedoc'),
		children: (
			<ItemTitleWithLozenge lozenge={formatMessage(messages.lozenge)}>
				{formatMessage(messages.title)}
			</ItemTitleWithLozenge>
		),
		onClick: enforceStorageLimit(handleClick),
		elemBefore: PageLiveDocIcon,
		...(!shouldBlockCreate && { href: createHref }),
		Wrapper: LiveDocSpotlightWrapper,
		testId: `create-menu-${shouldBlockCreate ? 'button' : 'link'}-item`,
	};
}
